.discovery p {
    font-size: 1rem;
}
.discovery h2 {
    font-size: 2rem;
}
.discovery h3 {
    font-weight: 500;
    font-size: 1.25rem;
}

.discovery h4 {
    font-size: 1.5rem;
}

.discovery p {
    margin-block-start: 0;
}